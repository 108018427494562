<template>
    <v-container fluid>
        <h1>Leads - Listing Page</h1>
        <v-divider class="my-4"></v-divider>
        <v-list>
            <v-list-item-group v-model="itemModel" class="menu-listgroup d-flex">
                <v-col sm="2" cols="3"
                v-for="(item, i) in items"
                :key="i">
                  <v-list-item>
                    {{ item.text }}
                  </v-list-item>
              </v-col>
            </v-list-item-group>
          </v-list>
        <v-card>

            <v-data-table
                :headers="headers"
                :items="listings"
                :search="search"
                :loading="loading"
            >
                <template v-slot:item.order="{item}">
                    {{ listings.indexOf(item)+1 }}
                </template>
                <template v-slot:item.created="{item}">
                    {{ item.created.substr(0, 10) }}
                </template>
                <template v-slot:item.contact="{ item }">
                    {{ item.email }}<br/>
                    {{ item.phone_number }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex my-4">
                        <router-link :to="{ name: 'lead-view', params: { id: item.id } } ">
                            <v-btn
                                color="primary"
                                rounded
                                class="ml-2"
                            >
                                View
                            </v-btn>
                        </router-link>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'LeadsListing',
        components: {

        },
        created() {

        },

        data() {
            return {
                search: '',
                itemModel: 0,
                items: [
                  { text: 'Open' },
                  { text: 'Ongoing' },
                  { text: 'Closed' },
                  { text: 'Canceled' },
                ],
                headers: [
                    {text: '#', value: 'order', sortable: false},
                    {text: 'Date of inquiry', value: 'created', sortable: false},
                    {text: 'Name', value: 'name'},
                    {text: 'Contact', value: 'contact', sortable: false},
                    {text: 'Assignee', value: 'assignee', sortable: false},
                    {text: 'Actions', value: 'actions', class: 'actions', sortable: false}
                ],
                loading: false,
                listings: [],
            }
        },
        methods: {
            ...mapActions(['getLeads']),

        },
        created() {
            this.loading = true;
            this.getLeads({ lead_type: 'listing', status: 'open'})
            .then((response) => {
                this.listings = response.data
                this.loading = false
            });
        },
        watch: {
            itemModel(val) {
                switch (val) {
                    case 0:
                        this.getLeads({ lead_type: 'listing', status: 'open'})
                        .then((response) => {
                          this.listings = response.data
                        });
                        break;
                    case 1:
                        this.getLeads({ lead_type: 'listing', status: 'ongoing'})
                        .then((response) => {
                          this.listings = response.data
                        });
                        break;
                    case 2:
                        this.getLeads({ lead_type: 'listing', status: 'closed'})
                        .then((response) => {
                          this.listings = response.data
                        });
                        break;
                    case 3:
                        this.getLeads({ lead_type: 'listing', status: 'cancelled'})
                        .then((response) => {
                          this.listings = response.data
                        });
                        break;

                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.date {
    min-width: 60px;
}
::v-deep {
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:not(:first-child),
    .v-data-table>.v-data-table__wrapper>table>thead>tr>th:not(:first-child) {
        padding: 0 4px;
    }
}
</style>
